export default {
  "404_text": "De pagina die u zoekt is niet gevonden.",
  "404_title": "Oeps! Je bent verdwaald.",
  "500_text": "De pagina die u zoekt is tijdelijk niet beschikbaar.",
  "500_title": "Oeps, we hebben een probleem!",
  "accept_terms": "Ik accepteer de <a href='/terms'>voorwaarden</a>",
  "access": "Toegang",
  "action": "Actie",
  "action_needed": "Actie vereist",
  "actions": "Acties",
  "active": "Actief",
  "add": "Toevoegen",
  "add_address": "Adres Toevoegen",
  "add_client": "Klanten Toevoegen",
  "add_customer": "Klant toevoegen",
  "add_files": "Voeg bestand(en) toe",
  "add_machine": "Machine toevoegen",
  "add_machines": "Machine toevoegen",
  "add_manufacturer": "Voeg Fabrikant Toe",
  "add_new_address": "Nieuw adres toevoegen",
  "add_new_limit": "Nieuwe limit toevoegen",
  "add_new_setting": "Nieuwe instelling toevoegen",
  "add_organization": "Organisatie Toevoegen",
  "add_plate": "Plaat toevoegen",
  "add_pricing": "Prijzen toevoegen",
  "add_profile": "Profiel toevoegen",
  "add_raw_material": "Grondstof toevoegen",
  "add_tool": "Gereedschap toevoegen",
  "add_user": "Gebruiker toevoegen",
  "add_webhook": "Webhook toevoegen",
  "add_webhooks": "Webhooks toevoegen",
  "added": "Toegevoegd",
  "address_has_removed": "Adres verwijderd",
  "address_info_updated": "Uw adres is ge-update",
  "address_line_1": "Adres regel 1",
  "address_line_2": "Adres regel 2",
  "address_line_3": "Adres regel 3",
  "addresses": "Adressen",
  "adjust": "Aanpassen",
  "all": "Alle",
  "all_added": "Alle toegevoegde",
  "all_changes_applied": "Alle wijzigingen worden toegepast op de selectie",
  "all_done": "Alles klaar",
  "all_fields_required": "Alle velden zijn vereist",
  "all_orders": "Aanvragen, offertes en bestellingen",
  "all_organizations": "Alle Organisaties",
  "almost_done": "Bijna klaar",
  "aluminium": "Aluminium",
  "any": "Elk",
  "api_documentation": "API documentatie",
  "apply": "Toepassen",
  "ask_customer_review_order": "Vraag de klant om de bestelling te beoordelen",
  "ask_customer_review_order_once_more": "Vraag de klant om de bestelling nog een keer te bekijken",
  "back_to_review": "Terug naar controleren",
  "assembly": "Assembleren",
  "attachments": "Bijlagen",
  "awaiting_customer": "Wachtend op klant",
  "back_to_produced": "Terug naar geproduceerd",
  "back_to_ordered": "Terug naar besteld",
  "back_to_all_orders": "Terug naar alle bestellingen",
  "back_to_edit": "Terug naar bewerken",
  "back_to_login": "Terug Naar Inloggen",
  "no_turning_back": "Bezig met verwerken...<br>U kunt nu niet meer terug.",
  "bend": "Zetten",
  "bend_sheet": "Buig plaat",
  "billing_address": "Factuur adres",
  "brush": "Borstelen",
  "business_days": "werkdagen",
  "cancel": "Annuleer",
  "cancel_order": "Annuleer Order",
  "cancel_order_go_back_to_quote": "Terug naar offerte",
  "cancel_order_go_back_to_review": "Bestelling annuleren, ga terug naar beoordelingsfase",
  "cancel_order_text": "Are you sure you want to cancel this order?",
  "cancelled": "Geannuleerd",
  "cannot_remove": "Kan niet verwijderd worden",
  "cannot_remove_shipping":
    "U kunt geen verzend adres verwijderen.<br><br>Zorg aub eerst dat u een ander verzendadres selecteert",
  "cannot_review": "Deze order wordt momenteel door de klant bewerkt en kan daarom nog niet worden beoordeeld",
  "ceramic": "Ceramiek",
  "certificates": "Certificaten",
  "change_password": "Wachtwoord veranderen",
  "check_spam":
    "Controleer uw spamfolder voordat u opnieuw een bevestigingsemail laat versturen.<br><br>Weet u zeker dat u opnieuw een bevestigingsemail wilt ontvangen?",
  "choose_raw_material": "Kies grondstof",
  "chose_new_password_text": "Kies een nieuw wachtwoord voor uw account.",
  "city": "Plaatsnaam",
  "client_type": "Klanttype:",
  "clients": "Klanten",
  "close": "Sluit",
  "company_domain": "Bedrijfs domein",
  "company_email": "Bedrijfs email",
  "company_info": "Bedrijfs Info",
  "company_name": "Bedrijfsnaam",
  "company_number": "KVK nummer",
  "company_phone": "Bedrijfs telefoonnummer",
  "company_settings": "Organisatie instellingen",
  "company_tax": "BTW nummer",
  "wait_for_quotation": "Wacht tot de offerte is gegenereerd",
  "complete": "Order compleet",
  "complete_steps": "Maak alle stappen compleet",
  "completed": "Voltooid",
  "composit": "Composiet",
  "configure_parts": "Producten configureren",
  "confirm_inquiry": "Bevestig aanvraag",
  "confirm_orders": "Bestellingen Bevestigen",
  "confirm_page_title": "Order bevestiging",
  "confirm_password": "Bevestig wachtwoord",
  "confirm_password_error_text": "Zorg ervoor dat beide wachtwoorden identiek zijn",
  "confirmed": "Bevestigd",
  "contains_threads": "Bevat threads",
  "continue": "Verder",
  "continue_after_customer_review_order": "De klant zal de bestelling beoordelen, daarna kunt u doorgaan.",
  "continue_after_manufacturer_quote_order": "De fabrikant geeft de bestelling op, daarna kunt u doorgaan.",
  "continue_submit_order": "Order plaatsen",
  "copied": "Gekopieerd",
  "copied_to_clipboard": "gekopieerd naar klembord",
  "copy_id": "Kopieer ID",
  "cost": "Kostprijs",
  "cost_e_m_unit": "Kostprijs [€/m]",
  "cost_e_m2_unit": "Kostprijs [€/m²]",
  "cost_per_sec": "Kostprijs per seconde",
  "cost_per_sec_with_unit": "Kostprijs per seconde [€/sec]",
  "could_not_generate_price":
    "We kunnen geen prijs toekennen aan de bovenstaande producten, deze dienen handmatig te worden beoordeeld. Ook staan deze producten niet bij de totaalprijs.",
  "country": "Land",
  "create_account": "Account Aanmaken",
  "create_order_for": "Order aanmaken voor",
  "created": "Gemaakt",
  "created_status": "Aangemaakt",
  "customer": "Klant",
  "to_produced": "Geproduceerd",
  "customer_decide_order": "De klant beslist of hij doorgaat met deze bestelling.",
  "customer_details": "Klantgegevens",
  "customer_turn": "Nu is de fabrikant aan de beurt",
  "customers": "Klanten",
  "cut": "Snijden",
  "cutting_profile": "Profiel snijder",
  "cutting_sheet": "Plaat snijder",
  "cycle_time": "Cyclus tijd",
  "dashboard": "Dashboard",
  "debur": "Ontbramen",
  "default": "Standaard",
  "default_shipping": "Standaard Verzendadres",
  "delete": "Verwijder",
  "delete_row": "Verwijderen",
  "delete_row_body": "Weet u zeker dat u deze rij wilt verwijderen?",
  "delete_rows_body": "Weet u zeker dat u deze rijen wilt verwijderen?",
  "delivery": "Verzenden",
  "delivery_date": "BEZORGDATUM",
  "delivery_date_small": "Bezorgdatum",
  "delivery_type_small": "Leveringstype",
  "density": "Dichtheid",
  "description": "Omschrijving",
  "deselect": "Deselecteren",
  "details": "Details",
  "development": "Ontwikkeling",
  "diameter": "Diameter",
  "diameter_mm": "Diameter [mm]",
  "dimensions": "Afmetingen",
  "domain": "Domein",
  "double_check_your_changes_before_using_the_next_button":
    "Controleer uw wijzigingen voordat u de knop Volgende gebruikt",
  "download": "Downloaden",
  "download_report_overview": "Rapportoverzicht downloaden",
  "drafts": "Concept",
  "drag_and_drop_your_files_here": "Sleep en zet uw bestanden hier neer",
  "edit": "Bewerk",
  "edit_address": "Bewerk Adres",
  "edit_batch": "Batch Bewerken",
  "edit_client": "Cliënten Bewerken",
  "edit_customer": "Klant bewerken",
  "edit_machine": "Bewerk machine",
  "edit_manufacturer": "Bewerk Fabrikant",
  "edit_multiple": "Bewerk meerdere",
  "edit_or_new_address": "Nieuw adres toevoegen of bewerken",
  "edit_order": "Bewerk Order",
  "edit_order_review": "Bewerk Beoordeelde Order",
  "edit_organization": "Organisatie Bewerken",
  "edit_plate": "Bewerk Plaat",
  "edit_pricing": "Prijs bewerken",
  "edit_profile": "Bewerk Profiel",
  "edit_tool": "Bewerk gereedschap",
  "edit_user": "Bewerk Gebruiker",
  "edit_webhooks": "Webhook bewerken",
  "email": "Email",
  "email_confirmed": "Bedankt, we hebben uw email adres geconfirmeerd",
  "endpoint": "URL",
  "endusers": "Eindgebruikers",
  "error": "Fout",
  "exceeds_standard": "Overschrijdt standaardtolerantie van ±0.125mm(±0.005)",
  "expires": "Vervalt",
  "export_data": "Gegevens exporteren",
  "extension": "Extensie",
  "extra_options": "Extra opties",
  "failed": "Mislukt",
  "failed_alert": "Er ging iets mis, probeer het opnieuw!",
  "failed_number": "Het aantal mag niet lager of gelijk aan 0 zijn.",
  "file": "Bestand",
  "file_added": "bestand toegevoegd",
  "filename": "Bestandsnaam",
  "files": "Bestanden",
  "files_added": "bestanden toegevoegd",
  "files_subtitle": "Alle geuploade bestanden",
  "fill_in_company_address": "Vul uw bedrijfsadres in",
  "fill_in_company_domain": "Vul uw bedrijfs domeinnaam in",
  "fill_in_company_email": "Vul uw bedrijfs email in",
  "fill_in_company_name": "Vul uw bedrijfsnaam in",
  "fill_in_company_number": "Vul uw KVK nummer in",
  "fill_in_company_phone": "Vul uw bedrijfs telefoonnummer in",
  "fill_in_company_tax": "Vul uw belastingnummer in",
  "fill_new_password": "Vul een nieuw wachtwoord in.",
  "filter": "Filter",
  "finally_you_can_use_the_following_function": "Ten slotte kunt u de volgende functie gebruiken",
  "finished": "Voltooid",
  "first_name": "Voornaam",
  "forbidden": "verboden",
  "forgot": "Vergeten",
  "forgot_password": "Wachtwoord Vergeten",
  "forgot_password_input_text":
    "Vul hieronder uw gebruikersnaam of e-mailadres in om uw wachtwoord opnieuw in te stellen.",
  "from_date": "Vanaf datum",
  "get_quotation": "Ontvang een offerte",
  "go_back": "Ga terug",
  "header_complete_steps": "Klik hier om alle stappen compleet te maken!",
  "height": "Hoogte",
  "height_mm": "Hoogte [mm]",
  "id": "Id",
  "import_and_merge_data": "Gegevens importeren en samenvoegen",
  "in_review": "In Beoordeling",
  "in_stock": "Op voorraad",
  "info_alert": "U heeft onopgeslagen wijzigingen.",
  "inner_radius": "Binnenradius",
  "insert": "Inpersen",
  "invalid_email": "Ongeldig email adres",
  "invalid_parts_text": "Sommige producten zijn niet geldig, controleer ze aub voordat u doorgaat",
  "is_active": "Actief",
  "is_available": "Beschikbaar",
  "is_optional": "Optioneel",
  "is_this_current_shipping": "Is dit uw huidige verzendadres?",
  "is_this_current_billing": "Is dit uw huidige factuuradres?",
  "is_valid": "Instellingen",
  "is_visible": "Zichtbaar",
  "labeling": "Labelen",
  "laser": "Laser",
  "last_name": "Achternaam",
  "last_updated": "Laatst geupdate:",
  "latency": "Wachttijd",
  "length": "Lengte",
  "length_mm": "Lengte [mm]",
  "limit_remove_sure": "Weet u zeker dat u deze limit wilt verwijderen?",
  "limits": "Limieten",
  "loading": "Laden...",
  "lock_account": "Account Vergrendelen",
  "login": "Login",
  "login_as": "Inloggen als",
  "login_as_username_confirmation":
    "Wilt u inloggen als <b>{msg}</b>?<br><br>Om weer terug te keren kunt u klikken op uw profiel",
  "logout": "Uitloggen",
  "logs": "Logboeken",
  "machines": "Machines",
  "mail_sent_message":
    "Als uw gebruikersnaam of e-mailadres bekend is in ons systeem, sturen we u een e-mail met reset-instructies. Controleer uw spammap als de e-mail na een paar minuten niet zichtbaar is.",
  "make_default": "Maak standaard",
  "make_default_shipping": "Maak standaard verzending",
  "make_sure_steps": "Om alle functionaliteiten te gebruiken dient u eerst alle stappen compleet te maken.",
  "manufacturer": "Fabrikant",
  "manufacturer_details": "Fabrikant Details",
  "wait_for_order_to_be_produced":
    "Wacht tot je bestelling is geproduceerd. Je wordt op de hoogte gesteld als het klaar is.",
  "order_is_produced_SHIPPING": "Uw bestelling is geproduceerd en wordt bezorgd.",
  "order_is_produced_PICKUP": "Uw bestelling is geproduceerd en klaar om afgehaald te worden.",
  "manufacturer_turn": "Nu is de fabrikant aan de beurt",
  "manufacturer_url": "Leverancier url",
  "manufacturers": "Fabrikanten",
  "manufacturing_organizations": "Productie-organisaties",
  "material": "Materiaal",
  "materials": "Materialen",
  "materials_overview": "Materialen overzicht",
  "source": "Bron",
  "weight": "Gewicht",
  "merge-component-ask-relation-a": "Welke kolom van het bestand moet worden toegewezen als sleutel waarde?",
  "merge-component-ask-relation-b": "Op welke bestaande sleutel wilt u de samenvoeggegevens samenvoegen?",
  "merge-component-back": "Terug",
  "merge-component-close": "Sluiten",
  "merge-component-column": "Column",
  "merge-component-confirm-save":
    "U staat op het punt de aangebrachte wijzigingen op te slaan. Weet je zeker dat je door wilt gaan?",
  "merge-component-csv": "CSV",
  "merge-component-done": "Klaar!",
  "merge-component-export-title": "Hoe wilt u uw bestand exporteren?",
  "merge-component-finish": "Afronden",
  "merge-component-loading": "Laden ...",
  "merge-component-modification": "Modificeren",
  "merge-component-new": "Nieuwe waarde",
  "merge-component-next": "Verder",
  "merge-component-original": "Origineel",
  "merge-component-overwrite": "Overschrijven",
  "merge-component-relation-validation": "Geef een geldige kolomnaam op om een ​​geldige relatie tot stand te brengen",
  "merge-component-save": "Opslaan",
  "merge-component-saving": "Opslaan ...",
  "merge-component-settings": "Samenvoeg Instellingen",
  "merge-component-start": "Begin",
  "merge-component-step": "Stap",
  "merge-component-sure": "Weet je het zeker? Hierna kun je niet meer terug.",
  "merge-component-title": "Data samenvoegen voor order",
  "merge-component-title-order": "Data samenvoegen",
  "merge-component-xlsx": "XLSX",
  "merger_component_explanation_text":
    "Deze wizard helpt u bij het importeren en/of bijwerken van batches met gegevens door een bestand te uploaden.<br>Als deze wizard is voltooid, krijgt u twee verschillende bestanden.<br>Beide bestanden houden de gegevens bij welk record is mislukt of geslaagd.<br>Hierdoor kunt u de verstrekte gegevens indien nodig corrigeren.",
  "merger_component_success_text": "Alles is goed verlopen!<br>Je kunt de resultaten zien als je deze wizard sluit.",
  "merger_component_totals_text":
    "U kunt het resultatenrapport van deze pagina downloaden.<br>Er zijn <b>{ totalSuccess }</b> records die zijn geslaagd.<br>Terwijl <b>{ totalFailed }</b> records niet konden worden verwerkt.",
  "merger_component_warning_text":
    "<strong>Let op</strong> dat de kolom <span style='text-decoration:underline'>{ idColumn }</span> wordt gebruikt om gegevensrijen te matchen.<br>Zorg er dus voor dat u heb die kolom in uw bestand niet gewijzigd.",
  "method": "Methode",
  "middle_name": "Middelnaam",
  "milling": "Frezen",
  "multiple_files": "Meerdere bestanden",
  "name": "Naam",
  "need_action": "Actie Nodig",
  "nestings": "Nestingen",
  "nestings_queue": "Nestings wachtrij",
  "new_address_saved": "Uw adres is opgeslagen",
  "new_here": "Nieuw hier",
  "new_limit_body": "Nieuwe limit toevoegen of bewerken",
  "new_nestings": "Nieuwe nesting",
  "new_order": "Nieuwe Bestelling",
  "new_password": "Nieuws wachtwoord",
  "new_setting_body": "Nieuwe instelling toevoegen of bewerken",
  "next": "De volgende",
  "no_actions": "Geen Acties",
  "no_changes": "Geen wijzigingen",
  "no_data": "Geen gegevens...",
  "no_description": "Geen omschrijving",
  "no_material": "Geen materiaal",
  "no_material_available": "Geen materiaal beschikbaar",
  "no_process": "Geen proces",
  "no_process_available": "Geen proces beschikbaar",
  "no_operations_available": "Geen bewerkingen beschikbaar",
  "no_operations_selected": "geen bewerkingen geselecteerd",
  "no_reference": "Geen Referentie",
  "no_results": "Geen resultaten...",
  "no_spaces_allowed": "Geen spaties toegestaan",
  "no_thanks": "Nee, bedankt",
  "none": "Geen",
  "not_set": "Niet ingesteld",
  "nothing": "Niets",
  "old_password": "Oud wachtwoord",
  "wrong_old_pasword": "Het oude wachtwoord was niet correct.",
  "oldpass_vs_new": "Uw oude en nieuwe wachtwoord zijn hetzelfde, probeer een ander wachtwoord",
  "operations": "bewerkingen",
  "options": "Opties",
  "or": "Of",
  "order": "Bestel",
  "order_cancelled_by_other_party": "De bestelling is geannuleerd door de andere partij.",
  "order_detail": "Besteldetail",
  "order_is_complete": "De bestelling is voltooid.",
  "order_nesting": "Order Nesten",
  "order_no": "Bestelnr.",
  "order_invalid": "Alle order regels moeten eerst correct zijn voordat u verder kunt gaan.",
  "order_received": "We hebben uw bestelling ontvangen en wordt binnen enige tijd beoordeeld",
  "order_received_final": "We hebben uw bestelling in goede handen ontvangen",
  "order_received_quoted":
    "We hebben uw order ontvangen. U ontvangt binnen enkele ogenblikken een email met uw offerte",
  "order_uncancel_text": "Alleen zij kunnen dat weer ongedaan maken, dus als je dat wilt, vraag het aan hen.",
  "ordered": "Besteld",
  "orders": "Orders",
  "orders_all": "Alle Orders",
  "org_failed_alert": "Er ging iets mis, probeer het opnieuw!",
  "org_info_alert": "U heeft onopgeslagen wijzigingen",
  "org_success_alert": "Uw bedrijfsgegevens zijn ge-update",
  "organic": "Organisch",
  "organization": "Organisatie",
  "organizations": "Organisaties",
  "orientation": "Oriëntatie",
  "other": "Anders",
  "outer_radius": "Buitenradius",
  "oxyacetylene": "Oxyacetylene",
  "packing": "Expeditie",
  "page_not_authorized_text": "U bent niet gemachtigd om deze pagina te zien.",
  "part_checker": "Deelcontrole",
  "part_review_manual": "Dit product dient<br>handmatig beoordeeld te worden",
  "part_name": "Part naam",
  "section": "Profiel",
  "parts": "Parts",
  "pattern_width": "Patroon breedte",
  "pattern_height": "Patroon hoogte",
  "total_contour_length": "Totale contourlengte",
  "total_contour_group": "Totaal aantal contourgroepen",
  "process_cycle_time": "Proces cyclustijd",
  "total_operation_cycle_time": "Totale cyclustijd van bewerkingen",
  "total_manufacturing_time": "Totale productie tijd",
  // deepcode ignore NoHardcodedPasswords: this is a translation, not a password
  "password": "Wachtwoord",
  "password_resetted":
    "Uw wachtwoord is opnieuw ingesteld en klaar voor gebruik. Klik op de onderstaande knop om verder te gaan.",
  "pcs": "stks",
  "pending_review": "Wachtend op Review",
  "per_unit": "Per stuk",
  "phone": "Telefoonnummer",
  "pick_address": "Kies Adres",
  "picked": "Geplukt",
  "pickup": "Ophalen",
  "pickup_address": "OPHAAL ADRES",
  "pickup_date": "OPHAALDATUM",
  "pickup_details": "Afhaalgegevens",
  "pickup_or_delivery": "Verzenden of ophalen?",
  "piece": "stuk",
  "place_order_client": "Weet u zeker dat u de order wilt plaatsen in naam van de klant?",
  "plans": "Snijplannen",
  "plasma": "Plasma",
  "plastic": "Kunstof",
  "plates": "Platen",
  "platform_oauth2_clients": "Platform OAuth2-clients",
  "platform_oauth2_tokens": "Platform OAuth2-tokens",
  "platform_oauth2_users": "Platform OAuth2-gebruikers",
  "please_describe_the_equation_using_only_the_following_variables":
    "Beschrijf de vergelijking met alleen de volgende variabelen",
  "please_describe_the_equation_without_variables": "beschrijf de vergelijking zonder variabelen",
  "preparation": "Werkvoorbereiding",
  "press_enter_remove": "Druk enter om te verwijderen",
  "press_enter_select": "Druk enter om te selecteren",
  "previous": "Vorig",
  "price": "Prijs",
  "pricing": "Prijzen",
  "pricings": "Prijzen",
  "priority": "Prioriteit",
  "private": "Privé",
  "process": "Proces",
  "processes": "Processen",
  "processing": "Verwerken",
  "produced": "Geproduceerd",
  "profile": "Profiel",
  "profile_failed_alert": "Er ging iets mis, probeer het opnieuw!",
  "profile_info_alert": "U heeft onopgeslagen wijzigingen",
  "profile_success_alert": "Uw profiel info is ge-update",
  "profiles": "Profielen",
  "province": "Provincie",
  "purchase": "Orders",
  "purchase_parts": "Koopdelen",
  "quantity": "Aantal",
  "quoted": "Geoffreerd",
  "quotes": "Quotes",
  "range_validation": "min moet kleiner of gelijk zijn aan max",
  "raw_materials": "Grondstoffen",
  "rectangular": "Rechthoek",
  "redirect_to_order_page": "U wordt doorverwezen naar uw order pagina",
  "reference": "Referentie",
  "register_as_organization": "Registreer als een organisatie",
  "register_manufacturer": "Registreer als leverancier",
  "register_now": "Nu registreren",
  "remove": "Verwijderen",
  "remove_address": "Verwijder adres",
  "remove_all": "Verwijder alles",
  "remove_all_invalid": "Weet u zeker dat u alle invalid parts wilt verwijderen?",
  "remove_batch": "Batch Verwijderen",
  "remove_batch_text": "Weet u zeker dat u deze batch wilt verwijderen?",
  "remove_following_address": "Weet u zeker dat u het onderstaand adres wilt verwijderen?",
  "removed": "Verwijderd",
  "reorder_part": "Opnieuw bestellen",
  "request_logs_of_api": "Verzoek Logs van API",
  "required_field": "Dit veld is vereist",
  "resend_email": "Email opnieuw versturen",
  "reset_failed": "Reset mislukt",
  "reset_failed_text": "We konden uw wachtwoord niet opnieuw instellen.",
  "reset_password": "Wachtwoord resetten",
  "resource": "Hulpbron",
  "response_size": "Grootte",
  "rest": "Resetten",
  "return_login": "Terug naar login",
  "retype_pass_not_same": "U heeft niet hetzelfde wachtwoord ingevoerd",
  "review": "Review",
  "review_order": "Beoordeel order",
  "review_order_and_next": "om deze bestelling te bekijken en naar de volgende fase te brengen.",
  "roles": "Rollen",
  "roll": "Walsen",
  "round": "Rond",
  "row": "Rij",
  "rows": "Rijen",
  "sales": "Verkoop",
  "save": "Opslaan",
  "saved": "Opgeslagen",
  "search": "Zoeken",
  "select": "Kies",
  "select_category": "Kies categorie",
  "select_certificates": "Selecteer certificaten",
  "select_customer": "Klant Selecteren",
  "select_customer_to_upload_file": "Klant Selecteren om Bestand te Uploaden",
  "select_lang": "Kies uw taal:",
  "select_manufacturer": "Selecteer Fabrikant",
  "select_manufacturer_to_upload_file": "Selecteer Fabrikant om Bestand te Uploaden",
  "select_manufacturers": "Selecteer Fabrikanten",
  "select_organization": "Selecteer organisatie",
  "select_pricings": "Selecteer prijzen",
  "select_raw_material": "Selecteer grondstof",
  "select_raw_materials": "Selecteer grondstoffen",
  "selected": "Geselecteerd",
  "send_quotation": "Verzend offerte",
  "quote": "Maak offerte",
  "setting_remove_sure": "Weet u zeker dat u deze instelling wilt verwijderen?",
  "settings": "Instellingen",
  "shape": "Vorm",
  "sheet": "Plaat",
  "sheets": "Platen",
  "shipped_on": "Verzend op",
  "shipping_address": "Verzendadres",
  "shipping_costs": "Verzendkosten",
  "shipping_details": "Verzendgegevens",
  "shortname": "Korte naam",
  "show": "Tonen",
  "show_or_hide_columns": "Toon / Verberg Kolommen",
  "show_output": "Bekijk output:",
  "sign_in_to_your_account": "Log in op uw account",
  "sign_up_recommendation_text":
    "Begin binnen enkele minuten met het bestellen van plaatwerkonderdelen. Meld u aan voor een gratis account via de onderstaande knop.",
  "signing_secret": "Signing secret",
  "size": "Grootte",
  "square": "Vierkant",
  "stainless": "Roestvaststaal",
  "start_order": "Nieuwe Order",
  "start_time": "Start tijd",
  "startup_time": "Startup tijd",
  "steel": "Staal",
  "steps_page": "Stappen",
  "stock": "Voorraad",
  "stock_cost": "Voorraad eenheidsprijs",
  "subtitle": "Ondertitel",
  "subtotal": "Subtotaal",
  "succeeded": "Geslaagd",
  "success": "Succes",
  "success_alert": "Uw gegevens zijn ge-update",
  "supported_files": "Ondersteunde bestanden: .step, .stp, .dxf",
  "switch_back": "Schakel Terug",
  "table_count": "Bekijk resultaten van {from} tm {to}|Bekijk {count} resultaten|Bekijk alleen resultaat",
  "take_me_back": "Breng me terug",
  "tax_21": "Incl. 21% BTW",
  "tax_number": "Belastingnummer",
  "terms_and_conditions": "Voorwaarden",
  "there_is_no_data_to_merge_with": "Er zijn geen gegevens om mee samen te voegen...",
  "thickness": "Dikte",
  "thickness_mm": "Dikte [mm]",
  "title": "Titel",
  "to_date": "Tot nu toe",
  "tokens": "Munten",
  "tools": "Gereedschappen",
  "total": "Totaal",
  "total_exclude_vat": "Totaal (excl. BTW)",
  "total_caps": "TOTAAL",
  "total_parts": "Totaal artikelen",
  "tube": "Buis",
  "type": "Type",
  "types": "types",
  "uncancel": "Annulering ongedaan maken",
  "unsaved_changes": "Onopgeslagen wijzigingen",
  "unsaved_changes_body": "U heeft onopgeslagen wijzigingen. Wilt u het alsnog opslaan?",
  "update_all": "Alles Updaten",
  "updated": "Laatst geupdate",
  "upload": "Uploaden",
  "upload_a_file": "Upload een bestand",
  "upload_body": "<i class='fa fa-cloud-upload fa-2x'></i> <br>Sleep al je bestanden hier naartoe",
  "upload_cad": "Upload CAD bestand",
  "upload_error": "Bestand niet geupload",
  "upload_file": "Upload bestand",
  "upload_modal_body":
    "<i class='fa fa-cloud-upload fa-2x'></i> <br>Sleep al je bestanden hier naartoe of <br><br><b-button class='btn btn-primary'><i class='fa fa-folder-open' style='color:white;'></i>&nbsp;Kies een bestand</b-button>",
  "user": "Gebruiker",
  "user_agent": "User agent",
  "user_settings": "Gebruikersinstellingen",
  "username": "Gebruikersnaam",
  "username_or_email": "Gebruikersnaam of E-mail",
  "username_or_email_is_required": "Gebruikersnaam of E-mailadres is Vereist",
  "users": "Gebruikers",
  "valid": "Geldig",
  "valid_parts": "Geldige Onderdelen",
  "values": "Waarden",
  "verification_email_send":
    "We have send you a verification email to complete your registration. If you do not see the email in your inbox, please check your spam or junk folder.",
  "verified": "Geverifieerd",
  "verify_email": "Verifieer uw email adres",
  "verify_email_modal_body":
    "U bent geregistreerd met <b>{msg}</b>, maar dit email adres is nog niet geverifieed. Het kan zijn dat de bevestigings email in uw spamfolder is beland.<br><br>Wilt u opnieuw een bevestigingsemail ontvangen?",
  "verify_email_modal_title": "Verifieer uw email adres",
  "waterjet": "Waterjet",
  "webhook": "Webhook",
  "webhooks": "Webhooks",
  "welcome_to_smartpart": "Welcome bij Smartpart",
  "width": "Breedte",
  "width_mm": "Breedte [mm]",
  "yes_please": "Ja, graag",
  "yes_sure": "Ja, ik ben zeker",
  "you_also_have_the_following_constants_available": "Je hebt ook de volgende constanten beschikbaar",
  "your_description": "Plaats uw omschrijving hier",
  "youve_got_mail": "Je Gebt Mail",
  "zip_code": "Postcode",
  "logo": "Logo",
  "change_logo": "Logo wijzigen",
  "image": "Afbeelding",
  "change_image": "Afbeelding wijzigen",
  "yes": "Ja",
  "no": "Nee",
  "new_row_are_not_allowed": "Nieuwe rij is niet toegestaan",
  "originally": "Oorspronkelijk",
  "no_sheet_found": "Geen blad gevonden",
  "variable_not_contain_value": "{ key } bevat geen waarde.",
  "variable_not_valid_option_with_suggestion": '"{ value }" is geen geldige optie. Bedoelde je "{ suggestion }"?',
  "variable_not_valid_option_with_options": (params: any) =>
    `"${params.named("value")}" is geen geldige optie.${
      !params.named("options")
        ? " Er is geen optie beschikbaar voor dit veld."
        : `\nU kunt kiezen uit:\n - ${params.named("options")}`
    }`,
  "3d_part_not_valid_option_with_options":
    '3D-onderdeel moet worden gevuld met patroondikte, "{ value }" is geen geldige optie.\n U kunt kiezen uit:\n - { options }',
  "variable_not_valid_number": '"{ value }" is geen geldig getal',
  "variable_should_be_round_number": '"{ value }" moet een rond getal zijn',
  "variable_shape_not_need_key_value": "{ shape } vorm moet geen { key } hebben",
  "variable_shape_needs_key_value": "{ shape } vorm heeft een { key } nodig",
  "there_are_errors": "Er zijn fouten, fix ze en upload een nieuw bestand!",
  "parameters": "Parameters",
  "events": "Evenementen",
  "code": "Code",
  "applying_changes": "Even wachten aub, we voeren de wijzigingen door.",
  "register": "Register",
  "register_as_organization_text":
    "Geef aan of u een account wilt aanmaken in naam van een organisatie of als een privé-entiteit.",
  "accept_the": "Ik accepteer de",
  "username_required": "Gebruikersnaam is vereist.",
  "password_required": "Wachtwoord is vereist.",
  "enter_email": "Voer een e-mailadres in.",
  "accept_term_and_condition": "Accepteer de algemene voorwaarden om door te gaan.",
  "accept_recaptcha": "Accepteer de recaptcha om door te gaan.",
  "getting_logo": "Logo ophalen",
  "loading_env": "Uw omgeving laden",
  "loading_production_env": "Uw productieomgeving wordt geladen",
  "powered_by": "Powered by",
  "verify": "Verifiëren",
  "email_verified":
    "Uw e-mailadres is geverifieerd en klaar om te bestellen. Log in met behulp van onderstaande knop om te beginnen met het bestellen van op maat gemaakt plaatwerk.",
  "queue": "Wachtrij",
  "moderator": "Moderator",
  "admin": "Beheeder",
  "internal": "Intern",
  "status": "Status",
  "timeout": "Time-out",
  "batches": "Batches",
  "nickname": "Bijnaam",
  "is_verified": "Geverifieerd",
  "is_customer": "Klant",
  "is_company": "Bedrijf",
  "is_internal": "Intern",
  "is_manufacturer": "Fabrikant?",
  "client_id": "Cliënt ID",
  "client_secret": "Geheim van de klant",
  "default_scopes": "Standaardbereiken",
  "redirect_uris": "Redirect uris",
  "token_type": "Tokentype",
  "access_token": "Toegangstoken",
  "refresh_token": "Token vernieuwen",
  "total_selected": "{total} geselecteerd",
  "change_address": "Adres Wijzigen",
  "wrong_username_or_password": "Verkeerde gebruikersnaam of wachtwoord",
  "user_has_no_manufacturer": `Gebruiker "{user}" heeft geen fabrikant, kan niet inloggen als deze gebruiker`,
  "user_not_found": "Gebruiker {userId} niet gevonden",
  "manufacturer_doesnt_exist": `Fabrikant "{manufacturer}" bestaat niet`,
  "failed_login_as": `Kan niet inloggen als gebruiker "{user}"`,
  "manufacturer_nickname_not_found": "Bijnaam fabrikant niet gevonden",
  "select_or_start_typing": "Selecteer of begin met typen",
  "no_certificates_available": "Geen certificaten beschikbaar",
  "manufacturer_not_active": "Fabrikant niet actief",
  "view_addresses": "Adressen bekijken",
  "failed_to_process_download": "Kan download niet verwerken, fout: {msg}",
  "failed_to_get_file": "Kon bestand niet ophalen, fout: {msg}",
  "order_not_found": "Bestelling niet gevonden",
  "preview": "Voorbeeld",
  "add_customer_user": "Klantgebruiker toevoegen",
  "view_customer_users": "Klantgebruikers bekijken",
  "add_or_edit_machine_limits": "Machinelimieten toevoegen of bewerken",
  "add_or_edit_machine_settings": "Opstart- en cyclustijden van machines toevoegen of bewerken",
  "download_nesting_file": `Download nesting {ext} bestand`,
  "download_nesting_zipped": `Download geneste {ext} bestanden (gezipt)`,
  "drop_files_to_upload": "Laat bestand(en) los om te uploaden",
  "session_expired_error": "Sessie verlopen, log opnieuw in",
  "scope": "Domein",
  "client_name": "Klantnaam",
  "client_uri": "Klant-URI",
  "login_as_session_expired_error": "Inloggen als sessie verlopen",
  "preview_text": `Voorbeeld {text}`,
  "fit_to_width": "Aanpassen aan breedte",
  "fit_to_height": "Aanpassen aan hoogte",
  "list_view": "Lijstweergave",
  "tree_view": "Boomweergave",
  "auth_method": "Auth-methode",
  "http_basic": "HTTP Basic",
  "http_post": "HTTP POST",
  "failed_to_process_filename": "Kan <b>{filename}</b> niet verwerken, fout: <b>{msg}</b>",
  "response_types": "Reactietypes",
  "preparing_files_totals": "Bestanden voorbereiden <strong><i>({completed}/{total})</i></strong>",
  "failed_to_generate_zip": "Kon ZIP-bestand niet genereren, {msg}",
  "order_summary": "Bestellingsoverzicht",
  "overall_price": "Totaalprijs",
  "manufacturer_pdf": "Fabrikant PDF",
  "startup_amount": "Startup -bedrag",
  "discount_amount": "kortingsbedrag",
  "shipping_amount": "Verzendingsbedrag",
  "tax_rate": "belastingtarief",
  "created_by": "gemaakt door",
  "set_pricing": "Prijs instellen",
  "apply_price_to_customers": "Prijs toepassen op klanten",
  "selected_customers": "Geselecteerde klant(en)",
  "pick_pricing": "Prijzen ophalen",
  "add_new_pricing": "Nieuwe prijzen toevoegen",
  "add_new_customer": "Nieuwe klant toevoegen",
  "pick_new_shipping_and_billing": "Kies een nieuw verzend- en factuuradres",
  "pick_new_shipping": "Kies een nieuw verzendadres",
  "pick_new_billing": "Kies een nieuw factuuradres",
  "make_default_billing": "Instellen als standaard factuuradres",
  "this_is_billing_address": "Dit is mijn factuuradres",
  "this_is_default_shipping_address": "Dit is mijn standaard verzendadres",
  "set_as_shipping": "Instellen als verzendadres",
  "set_as_billing": "Instellen als factuuradres",
  "default_shipping_address": "Standaard Verzendadres",
  "failed_to_upload": `Kan bestand niet uploaden, fout: "{message}"`,
  "file_duplicated": `Bestand <strong>"{filename}"</strong> bestaat al, selecteer een actie om door te gaan`,
  "proceed": "Doorgaan",
  "pick_new_shipping_and_billing_to_remove_address":
    "Kies een nieuw adres als standaard verzend- en factuuradres voordat het adres kan worden verwijderd.",
  "pick_new_shipping_to_remove_address":
    "Kies een nieuw standaard verzendadres voordat het adres kan worden verwijderd",
  "pick_new_billing_to_remove_address": "Kies een nieuw standaard factuuradres voordat het adres kan worden verwijderd",
  "is_moderator": "Moderator",
  "is_admin": "Beheerder",
  "only_show_changed_data": "Toon alleen gewijzigde gegevens",
}
