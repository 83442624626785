import { aOrAn } from "./utils"

export default {
  "404_text": "The page you are looking for was not found.",
  "404_title": "Oops! You're lost.",
  "500_text": "The page you are looking for is temporarily unavailable.",
  "500_title": "Oops, we have a problem!",
  "accept_terms": "I accept the <a href='/terms'>terms and conditions</a>",
  "access": "Access",
  "action": "Action",
  "action_needed": "Action needed",
  "actions": "Actions",
  "active": "Active",
  "add": "Add",
  "add_address": "Add Address",
  "add_client": "Add Clients",
  "add_customer": "Add customer",
  "add_files": "Add file(s)",
  "add_machine": "Add machine",
  "add_machines": "Add machine",
  "add_manufacturer": "Add Manufacturer",
  "add_new_address": "Add new address",
  "add_new_limit": "Add new limit",
  "add_new_setting": "Add new setting",
  "add_organization": "Add Organization",
  "add_plate": "Add sheet",
  "add_pricing": "Add Pricing",
  "add_profile": "Add profile",
  "add_raw_material": "Add raw material",
  "add_tool": "Add tool",
  "add_user": "Add user",
  "add_webhook": "Add webhook",
  "add_webhooks": "Add webhooks",
  "added": "Added",
  "address_has_removed": "Address has been removed",
  "address_info_updated": "Your address info has been updated!",
  "address_line_1": "Address line 1",
  "address_line_2": "Address line 2",
  "address_line_3": "Address line 3",
  "addresses": "Addresses",
  "adjust": "Adjust",
  "all": "All",
  "all_added": "All added",
  "all_changes_applied": "All changes will be applied on the selected files",
  "all_done": "All Done",
  "all_fields_required": "All fields are required",
  "all_orders": "Inquiries, quotations and purchase orders",
  "all_organizations": "All Organizations",
  "almost_done": "Almost done",
  "aluminium": "Aluminium",
  "any": "Any",
  "api_documentation": "API Documentation",
  "apply": "Apply",
  "ask_customer_review_order": "Ask customer to review order",
  "ask_customer_review_order_once_more": "Ask customer to review the order once more",
  "back_to_review": "Back to review",
  "assembly": "Assembly",
  "attachments": "Attachments",
  "awaiting_customer": "Awaiting for customer",
  "back_to_produced": "Back to produced",
  "back_to_ordered": "Back to ordered",
  "back_to_all_orders": "Back to All Orders",
  "back_to_edit": "Back to edit",
  "back_to_login": "Back to Login",
  "no_turning_back": "Merging and saving...<br>There's no turning back now.",
  "bend": "Bending",
  "bend_sheet": "Bend sheet",
  "billing_address": "Billing Address",
  "brush": "Brush",
  "business_days": "business days",
  "cancel": "Cancel",
  "cancel_order": "Cancel Order",
  "cancel_order_go_back_to_quote": "Back to quote",
  "cancel_order_go_back_to_review": "Cancel order, go back to review stage",
  "cancel_order_text": "Are you sure you want to cancel this order?",
  "cancelled": "Cancelled",
  "cannot_remove": "Cannot remove",
  "cannot_remove_shipping":
    "You cannot remove a shipping address.<br><br>Please make sure to use a different shipping address first.",
  "cannot_review": "This order is currently being edited by the customer and therefor cannot be reviewed yet",
  "ceramic": "Ceramic",
  "certificates": "Certificates",
  "change_password": "Change Password",
  "check_spam":
    "Check your spam or junk folder before you resend the email.<br><br>Are you sure that you would like to resend the email?",
  "choose_raw_material": "Choose raw material",
  "chose_new_password_text": "Please chose a new password for your account.",
  "city": "City / Town",
  "client_type": "Client Type",
  "clients": "Clients",
  "close": "Close",
  "company_domain": "Company Domain",
  "company_email": "Company email",
  "company_info": "Company Info",
  "company_name": "Company name",
  "company_number": "Company number (KVK)",
  "company_phone": "Company phone number",
  "company_settings": "Company Settings",
  "company_tax": "VAT number",
  "wait_for_quotation": "Wait till the quotation is generated",
  "complete": "Complete",
  "complete_steps": "Complete all steps below",
  "completed": "Completed",
  "composit": "Composit",
  "configure_parts": "Configure parts",
  "confirm_inquiry": "Confirm inquiry",
  "confirm_orders": "Confirm Orders",
  "confirm_page_title": "Order confirmation",
  "confirm_password": "Confirm password",
  "confirm_password_error_text": "Make sure both passwords are identical",
  "confirmed": "Confirmed",
  "contains_threads": "Contains threads",
  "continue": "Continue",
  "continue_after_customer_review_order": "The customer will review the order, after that you can continue.",
  "continue_after_manufacturer_quote_order": "The manufacturer will quote the order, after that you can continue.",
  "continue_submit_order": "Continue to submit order",
  "copied": "Copied",
  "copied_to_clipboard": "copied to clipboard",
  "copy_id": "Copy ID",
  "cost": "Cost",
  "cost_e_m_unit": "Cost [€/m]",
  "cost_e_m2_unit": "Cost [€/m²]",
  "cost_per_sec": "Cost price per second",
  "cost_per_sec_with_unit": "Cost per second [€/sec]",
  "could_not_generate_price":
    "We could not generate a price for the products above and needs to be reviewed manually. We did not add them to the total price",
  "country": "Country",
  "create_account": "Create Account",
  "create_order_for": "Create order for",
  "created": "Created",
  "created_status": "Created",
  "customer": "Customer",
  "to_produced": "Produced",
  "customer_decide_order": "The customer will decide whether to continue with this order.",
  "customer_details": "Customer details",
  "customer_turn": "It's now the manufacturer's turn",
  "customers": "Customers",
  "cut": "Cutting",
  "cutting_profile": "Cutting profile",
  "cutting_sheet": "Cutting sheet",
  "cycle_time": "Cycle time",
  "dashboard": "Dashboard",
  "debur": "Debur",
  "default": "Default",
  "default_shipping": "Default Shipping",
  "delete": "Delete",
  "delete_row": "Remove",
  "delete_row_body": "Are you sure you want to delete this row?",
  "delete_rows_body": "Are you sure you want to delete these rows?",
  "delivery": "Delivery",
  "delivery_date": "DELIVERY DATE",
  "delivery_date_small": "Delivery date",
  "delivery_type_small": "Delivery type",
  "density": "Density",
  "description": "Description",
  "deselect": "Deselect",
  "details": "Details",
  "development": "Development",
  "diameter": "Diameter",
  "diameter_mm": "Diameter [mm]",
  "dimensions": "Dimensions",
  "domain": "Domain",
  "double_check_your_changes_before_using_the_next_button": "Double check your changes before using the Next button",
  "download": "Download",
  "download_report_overview": "Download report overview",
  "drafts": "Drafts",
  "drag_and_drop_your_files_here": "Drag and drop your files here",
  "edit": "Edit",
  "edit_address": "Edit Address",
  "edit_batch": "Edit Batch",
  "edit_client": "Edit Clients",
  "edit_customer": "Edit customer",
  "edit_machine": "Edit machine",
  "edit_manufacturer": "Edit Manufacturer",
  "edit_multiple": "Edit multiple",
  "edit_or_new_address": "Add or edit new addresses",
  "edit_order": "Edit Order",
  "edit_order_review": "Edit Reviewed Order",
  "edit_organization": "Edit Organization",
  "edit_plate": "Edit sheet",
  "edit_pricing": "Edit Pricing",
  "edit_profile": "Edit profile",
  "edit_tool": "Edit tool",
  "edit_user": "Edit User",
  "edit_webhook": "Edit webhook",
  "email": "Email",
  "email_confirmed": "Thank you, we have confirmed your email address",
  "endpoint": "Endpoint",
  "endusers": "Endusers",
  "error": "Error",
  "exceeds_standard": "Exceeds standard tollerance of ±0.125mm(±0.005)",
  "expires": "Expires",
  "export_data": "Export Data",
  "extension": "Extension",
  "extra_options": "Extra options",
  "failed": "Failed",
  "failed_alert": "Something went wrong! Your details are not updated!",
  "failed_number": "Quantity cannot be less or equal then 0",
  "file": "File",
  "file_added": "file added",
  "filename": "Filename",
  "files": "Files",
  "files_added": "files added",
  "files_subtitle": "All uploaded files",
  "fill_in_company_address": "Fill in your company address",
  "fill_in_company_domain": "Fill in your company domain",
  "fill_in_company_email": "Fill in your company email",
  "fill_in_company_name": "Fill in your company name",
  "fill_in_company_number": "Fill in your company number (KVK)",
  "fill_in_company_phone": "Fill in your company phonenumber",
  "fill_in_company_tax": "Fill in your company TAX number",
  "fill_new_password": "Please fill in a new password.",
  "filter": "Filter",
  "finally_you_can_use_the_following_function": "Finally, you can use the following function",
  "finished": "Finished",
  "first_name": "First name",
  "forbidden": "Forbidden",
  "forgot": "Forgot",
  "forgot_password": "Forgot Password",
  "forgot_password_input_text": "Please fill in your username or email address below to reset your password.",
  "from_date": "From Date",
  "get_quotation": "Get Quotation",
  "go_back": "Go back",
  "header_complete_steps": "Click here to complete all steps!",
  "height": "Height",
  "height_mm": "Height [mm]",
  "id": "Id",
  "import_and_merge_data": "Import & merge data",
  "in_review": "In Review",
  "in_stock": "In stock",
  "info_alert": "It seems you have made any changes to save yet.",
  "inner_radius": "Inner Radius",
  "insert": "Insert",
  "invalid_email": "Invalid e-mail address!",
  "invalid_parts_text": "Some parts aren't valid, please check them before submitting your order",
  "is_active": "Active",
  "is_available": "Available",
  "is_optional": "Optional",
  "is_this_current_shipping": "Is this your current shipping address?",
  "is_this_current_billing": "Is this your current billing address?",
  "is_valid": "Settings",
  "is_visible": "Visible",
  "labeling": "Labeling",
  "laser": "Laser",
  "last_name": "Last name",
  "last_updated": "Last updated:",
  "latency": "Latency",
  "length": "Length",
  "length_mm": "Length [mm]",
  "limit_remove_sure": "Are you sure that you want to remove this limit?",
  "limits": "Limits",
  "loading": "Loading...",
  "lock_account": "Lock Account",
  "login": "Login",
  "login_as": "Login as",
  "login_as_username_confirmation":
    "Would you like to login as <b>{msg}</b>?<br><br>You can switch back by clicking on your profile",
  "logout": "Logout",
  "logs": "Logs",
  "machines": "Machines",
  "mail_sent_message":
    "If your username or email address is know in our systen we will send you an email with reset instructions. Please check your spam folder if the email is not visible after a couple of minutes.",
  "make_default": "Make default",
  "make_default_shipping": "Make default shipping",
  "make_sure_steps": "Please make sure to complete all steps below in order to use all features.",
  "manufacturer": "Manufacturer",
  "manufacturer_details": "Manufacturer Details",
  "wait_for_order_to_be_produced": "Wait till your order is produced. You will be notified when it is ready.",
  "order_is_produced_SHIPPING": "Your order is produced and on its way to you.",
  "order_is_produced_PICKUP": "Your order is produced and ready for pick up.",
  "manufacturer_turn": "It's now the manufacturer's turn",
  "manufacturer_url": "Manfacturer url",
  "manufacturers": "Manufacturers",
  "manufacturing_organizations": "Manufacturing Organizations",
  "material": "Material",
  "materials": "Materials",
  "materials_overview": "Materials overview",
  "source": "Source",
  "weight": "Weight",
  "merge-component-ask-relation-a": "Which column of the file should be used as key value?",
  "merge-component-ask-relation-b": "On which existing key do you want to merge the merge data?",
  "merge-component-back": "Back",
  "merge-component-close": "Close",
  "merge-component-column": "Column",
  "merge-component-confirm-save": "You are about to save the changes you made. Are you sure you want to continue?",
  "merge-component-csv": "CSV",
  "merge-component-done": "Done!",
  "merge-component-export-title": "How would you like to export your file?",
  "merge-component-finish": "Finish",
  "merge-component-loading": "Loading ...",
  "merge-component-modification": "Modification",
  "merge-component-new": "New Value",
  "merge-component-next": "Next",
  "merge-component-original": "Original",
  "merge-component-overwrite": "Overwrite",
  "merge-component-relation-validation": "Please provide a valid column name to make a valid relationship",
  "merge-component-save": "Save",
  "merge-component-saving": "Saving ...",
  "merge-component-settings": "Merge Settings",
  "merge-component-start": "Start",
  "merge-component-step": "Step",
  "merge-component-sure": "Are you sure? There's no going back after this.",
  "merge-component-title": "Merge data",
  "merge-component-title-order": "Merge data for order",
  "merge-component-xlsx": "XLSX",
  "merger_component_explanation_text":
    "This wizard will help you to import and/or update batches of data by uploading a file.<br>When this wizard is finished you will endup with two different files.<br>Both of those files keep track of which record failed or succeeded.<br>This allows you to fix the provided data if necessary.",
  "merger_component_success_text": "Everything went fine!<br>You can see the results when you close this wizard.",
  "merger_component_totals_text":
    "You can download the result report from this page.<br>There are <b>{ totalSuccess }</b> records which succeeded.<br>While <b>{ totalFailed }</b> records failed to process.",
  "merger_component_warning_text":
    "<strong>Be aware</strong> that the <span style='text-decoration:underline'>{ idColumn }</span> column is used to match data rows.<br>So make sure you haven't changed that column in your file.",
  "method": "Method",
  "middle_name": "Middle name",
  "milling": "Milling",
  "multiple_files": "Multiple Files",
  "name": "Name",
  "need_action": "Need Action",
  "nestings": "Nestings",
  "nestings_queue": "Nestings queue",
  "new_address_saved": "Your new address has been saved!",
  "new_here": "New Here",
  "new_limit_body": "Add or edit a new limit",
  "new_nestings": "New Nestings",
  "new_order": "New Order",
  "new_password": "New Password",
  "new_setting_body": "Add or edit a new setting",
  "next": "Next",
  "no_actions": "No Actions",
  "no_changes": "No changes",
  "no_data": "No data...",
  "no_description": "No description",
  "no_material": "No material",
  "no_material_available": "No material available",
  "no_process": "No process",
  "no_process_available": "No process available",
  "no_operations_available": "No operations available",
  "no_operations_selected": "no operations selected",
  "no_reference": "No Reference",
  "no_results": "No results...",
  "no_spaces_allowed": "No spaces allowed",
  "no_thanks": "No thanks",
  "none": "None",
  "not_set": "Not set",
  "nothing": "Nothing",
  "old_password": "Old Password",
  "wrong_old_pasword": "The old password was not correct.",
  "oldpass_vs_new": "Your old password is the same, make sure to use a different password",
  "operations": "Operations",
  "options": "Options",
  "or": "Or",
  "order": "Order",
  "order_cancelled_by_other_party": "The order was canceled by the other party.",
  "order_detail": "Order Detail",
  "order_is_complete": "The order is complete.",
  "order_nesting": "Order Nesting",
  "order_no": "Order No.",
  "order_invalid": "All the order items must be valid before you can continue.",
  "order_received": "We have received your order and will be in review shortly",
  "order_received_final": "We have received your order and will shipped soon",
  "order_received_quoted": "We have received your order. You will get a quotation within a few moments per email",
  "order_uncancel_text": "Only they can uncancel it, so if you want that, ask them.",
  "ordered": "Ordered",
  "orders": "Orders",
  "orders_all": "All Orders",
  "org_failed_alert": "Something went wrong! Your details are not updated!",
  "org_info_alert": "It seems you have made any changes to save yet.",
  "org_success_alert": "Your company info has been updated!",
  "organic": "Organic",
  "organization": "Organization",
  "organizations": "Organizations",
  "orientation": "Orientation",
  "other": "Other",
  "outer_radius": "Outer Radius",
  "oxyacetylene": "Oxyacetylene",
  "packing": "Packing",
  "page_not_authorized_text": "You are not authorized to see this page.",
  "part_checker": "Part Checker",
  "part_review_manual": "This part needs to<br>be manually reviewed",
  "parts": "Parts",
  "password": "Password",
  "password_resetted": "Your password has been reset and is ready for use. Click the button below to continue.",
  "pcs": "pcs",
  "pending_review": "Pending Review",
  "per_unit": "Per unit",
  "phone": "Phone number",
  "pick_address": "Pick Address",
  "picked": "Picked",
  "pickup": "Pickup",
  "pickup_address": "PICKUP ADDRESS",
  "pickup_date": "PICKUP DATE",
  "pickup_details": "Pickup details",
  "pickup_or_delivery": "Pickup or delivery?",
  "piece": "piece",
  "place_order_client": "Are you sure you want to place the order on behalf of the customer?",
  "plans": "Plans",
  "plasma": "Plasma",
  "plastic": "Plastic",
  "plates": "Sheets",
  "platform_oauth2_clients": "Platform OAuth2 Clients",
  "platform_oauth2_tokens": "Platform OAuth2 Tokens",
  "platform_oauth2_users": "Platform OAuth2 Users",
  "please_describe_the_equation_using_only_the_following_variables":
    "Please describe the equation using only the following variables",
  "please_describe_the_equation_without_variables": "please describe the equation without variables",
  "preparation": "Perparation",
  "press_enter_remove": "Press enter to remove",
  "press_enter_select": "Press enter to select",
  "previous": "Previous",
  "price": "Price",
  "pricing": "Pricing",
  "pricings": "Pricings",
  "priority": "Priority",
  "private": "Private",
  "process": "Process",
  "processes": "Processes",
  "processing": "Processing",
  "produced": "Produced",
  "profile": "Profile",
  "profile_failed_alert": "Something went wrong! Your details are not updated!",
  "profile_info_alert": "It seems you have made any changes to save yet.",
  "profile_success_alert": "Your profile info has been updated!",
  "profiles": "Profiles",
  "province": "State / Province / Region",
  "purchase": "Orders",
  "purchase_parts": "Purchase Parts",
  "quantity": "Quantity",
  "quoted": "Quoted",
  "quotes": "Quotes",
  "range_validation": "min must be smaller or equal to max",
  "raw_materials": "Raw materials",
  "rectangular": "Rectangular",
  "redirect_to_order_page": "You will be redirected to your order page",
  "reference": "Reference",
  "register_as_organization": "Register as an organization",
  "register_manufacturer": "Register as a Manufacturer",
  "register_now": "Register Now",
  "remove": "Remove",
  "remove_address": "Remove address",
  "remove_all": "Remove all",
  "remove_all_invalid": "Are you sure that you want to remove all invalid parts?",
  "remove_batch": "Remove Batch",
  "remove_batch_text": "Are you sure you want to delete this batch?",
  "remove_following_address": "Are you sure you want to remove the following address?",
  "removed": "Removed",
  "reorder_part": "Reorder part",
  "request_logs_of_api": "Request logs of API",
  "required_field": "This field is required!",
  "resend_email": "Resend email",
  "reset": "Reset",
  "reset_failed": "Reset Failed",
  "reset_failed_text": "We could not reset your password.",
  "reset_password": "Reset Password",
  "resource": "Resource",
  "response_size": "Response size",
  "return_login": "Return to login",
  "retype_pass_not_same": "You did not re-typed the same password",
  "review": "Review",
  "review_order": "Review order",
  "review_order_and_next": "to review this order and set it to the next stage.",
  "roles": "Roles",
  "roll": "Roll",
  "round": "Round",
  "row": "Row",
  "rows": "Rows",
  "sales": "Sales",
  "save": "Save",
  "saved": "Saved",
  "search": "Search",
  "select": "Select",
  "select_category": "Select category",
  "select_certificates": "Select certificates",
  "select_customer": "Select Customer",
  "select_customer_to_upload_file": "Select Customer to Upload File",
  "select_lang": "Select your language:",
  "select_manufacturer": "Select Manufacturer",
  "select_manufacturer_to_upload_file": "Select Manufacturer to Upload File",
  "select_manufacturers": "Select Manufacturers",
  "select_organization": "Select Organization",
  "select_pricings": "Select Pricings",
  "select_raw_material": "Select raw material",
  "select_raw_materials": "Select raw materials",
  "selected": "Selected",
  "send_quotation": "Send quotation",
  "quote": "Quote",
  "setting_remove_sure": "Are you sure that you want to remove this setting?",
  "settings": "Settings",
  "shape": "Shape",
  "sheet": "Sheet",
  "sheets": "Sheets",
  "shipped_on": "Shipped on",
  "shipping_address": "Shipping Address",
  "shipping_costs": "Shipping costs",
  "shipping_details": "Shipping details",
  "shortname": "Short name",
  "show": "Show",
  "show_or_hide_columns": "Show / Hide Columns",
  "show_output": "Show output:",
  "sign_in_to_your_account": "Sign in to your account",
  "sign_up_recommendation_text":
    "Start ordering sheet metal parts within minutes. Signup for a free account using the button below.",
  "signing_secret": "Signing secret",
  "size": "Size",
  "square": "Square",
  "stainless": "Stainless",
  "start_order": "New Order",
  "start_time": "Start time",
  "startup_time": "Startup time",
  "steel": "Steel",
  "steps_page": "Steps",
  "stock": "Stock",
  "stock_cost": "Stock unit cost",
  "subtitle": "Subtitle",
  "subtotal": "Subtotal",
  "succeeded": "Succeeded",
  "success": "Success",
  "success_alert": "Your info has been updated!",
  "supported_files": "Supported files: .step, .stp, .dxf",
  "switch_back": "Switch Back",
  "table_count": "Showing records {from} to {to}|Showing all {count} records|Showing only record",
  "take_me_back": "Take Me Back",
  "tax_21": "Incl. Tax 21%",
  "tax_number": "Tax Number",
  "terms_and_conditions": "Terms and Conditions",
  "there_is_no_data_to_merge_with": "There is no data to merge with...",
  "thickness": "Thickness",
  "thickness_mm": "Thickness [mm]",
  "title": "Title",
  "to_date": "To Date",
  "tokens": "Tokens",
  "tools": "Tools",
  "total": "Total",
  "total_exclude_vat": "Total (excl. VAT)",
  "total_caps": "TOTAL",
  "total_parts": "Total parts",
  "tube": "Tube",
  "type": "Type",
  "types": "Types",
  "uncancel": "Undo cancellation",
  "unsaved_changes": "Unsaved changes",
  "unsaved_changes_body": "You have unsaved changes. Would you like to save it anyway?",
  "update_all": "Update All",
  "updated": "Updated",
  "upload": "Upload",
  "upload_a_file": "Upload a file",
  "upload_body": "<i class='fa fa-cloud-upload fa-2x'></i> <br>Drag and drop your files here",
  "upload_cad": "Upload CAD file",
  "upload_error": "File not uploaded",
  "upload_file": "Upload file",
  "upload_modal_body":
    "<i class='fa fa-cloud-upload fa-2x'></i> <br>Drag and drop your files here or <br><br><b-button class='btn btn-primary'><i class='fa fa-folder-open' style='color:white;'></i>&nbsp;Upload a file</b-button>",
  "user": "User",
  "user_agent": "User agent",
  "user_settings": "User Settings",
  "username": "Username",
  "username_or_email": "Username or Email",
  "username_or_email_is_required": "Username or Email is Required",
  "users": "Users",
  "valid": "Valid",
  "valid_parts": "Valid Parts",
  "values": "Values",
  "verification_email_send":
    "We have send you a verification email to complete your registration. If you do not see the email in your inbox, please check your spam or junk folder.",
  "verified": "Verified",
  "verify_email": "Verify your email address",
  "verify_email_modal_body":
    "You are registered with <b>{msg}</b>, but this email address is still not verified. This might be ended up in your spamfolder.<br><br>Would you like to resend a verification email?",
  "verify_email_modal_title": "Verify your email address",
  "waterjet": "Waterjet",
  "webhook": "Webhook",
  "webhooks": "Webhooks",
  "welcome_to_smartpart": "Welcome to Smartpart",
  "width": "Width",
  "width_mm": "Width [mm]",
  "yes_please": "Yes, please",
  "yes_sure": "Yes, I am sure",
  "you_also_have_the_following_constants_available": "You also have the following constants available",
  "your_description": "Enter your description here",
  "youve_got_mail": "You've got mail",
  "zip_code": "Zip / Postal Code",
  "logo": "Logo",
  "change_logo": "Change logo",
  "image": "Image",
  "change_image": "Change image",
  "part_name": "Part name",
  "section": "Section",
  "pattern_width": "Pattern width",
  "pattern_height": "Pattern height",
  "total_contour_length": "Total contour length",
  "total_contour_group": "Total amount of contour groups",
  "process_cycle_time": "Process cycle time",
  "total_operation_cycle_time": "Total operation cycle time",
  "total_manufacturing_time": "Total manufacturing time",
  "yes": "Yes",
  "no": "No",
  "new_row_are_not_allowed": "New row are not allowed",
  "originally": "Originally",
  "no_sheet_found": "No sheet found",
  "variable_not_contain_value": "{ key } does not contain any value.",
  "variable_not_valid_option_with_suggestion": '"{ value }" is not a valid option. Did you mean "{ suggestion }"?',
  "variable_not_valid_option_with_options": (params: any) =>
    `"${params.named("value")}" is not a valid option.${
      !params.named("options")
        ? " There's no option available for this field."
        : `\nYou can choose from:\n - ${params.named("options")}`
    }`,
  "3d_part_not_valid_option_with_options":
    '3D part must be filled with pattern thickness, "{ value }" is not a valid option.\nYou can choose from:\n - { options }',
  "variable_not_valid_number": '"{ value }" is not a valid number',
  "variable_should_be_round_number": '"{ value }" should be a round number',
  "variable_shape_not_need_key_value": ctx =>
    `${ctx.named("shape")} shape shouldn't have ${aOrAn(ctx.named("key"))} ${ctx.named("key")}`,
  "variable_shape_needs_key_value": ctx =>
    `${ctx.named("shape")} shape needs ${aOrAn(ctx.named("key"))} ${ctx.named("key")}`,
  "there_are_errors": "There are errors, fix them and upload a new file!",
  "parameters": "Parameters",
  "events": "Events",
  "code": "Code",
  "applying_changes": "Please wait, we're applying the changes.",
  "register": "Register",
  "register_as_organization_text":
    "Please indicate if you would like to create an account in name of an organization or as a private entity.",
  "accept_the": "I accept the",
  "username_required": "Username is required.",
  "password_required": "Password is required.",
  "enter_email": "Please enter an email address.",
  "accept_term_and_condition": "Please accept the terms and conditions to continue.",
  "accept_recaptcha": "Please accept the recaptcha to continue.",
  "getting_logo": "Getting logo",
  "loading_env": "Loading your environment",
  "loading_production_env": "Loading your production environment",
  "powered_by": "Powered by",
  "verify": "Verify",
  "email_verified":
    "Your email address is verified an ready to order. Login using the botton below to start ordering custom sheet metal.",
  "queue": "Queue",
  "moderator": "Moderator",
  "admin": "Admin",
  "internal": "Internal",
  "status": "Status",
  "timeout": "Timeout",
  "batches": "Batches",
  "nickname": "Nickname",
  "is_verified": "Verified",
  "is_customer": "Customer",
  "is_company": "Company",
  "is_internal": "Internal",
  "is_manufacturer": "Manufacturer?",
  "client_id": "Client's ID",
  "client_secret": "Client's Secret",
  "default_scopes": "Default scopes",
  "redirect_uris": "Redirect uris",
  "token_type": "Token type",
  "access_token": "Access token",
  "refresh_token": "Refresh token",
  "total_selected": "{total} selected",
  "change_address": "Change Address",
  "wrong_username_or_password": "Wrong username or password",
  "user_has_no_manufacturer": `User "{user}" has no manufacturer, cannot log in as this user`,
  "user_not_found": "User {userId} not found",
  "manufacturer_doesnt_exist": `Manufacturer "{manufacturer}" doesn't exist`,
  "failed_login_as": `Failed to login as user "{user}"`,
  "manufacturer_nickname_not_found": "Manufacturer nickname not found",
  "select_or_start_typing": "Select or start typing",
  "no_certificates_available": "No certificates available",
  "manufacturer_not_active": "Manufacturer not active",
  "view_addresses": "View addresses",
  "failed_to_process_download": "Failed to process download, error: {msg}",
  "failed_to_get_file": "Failed to get file, error: {msg}",
  "order_not_found": "Order not found",
  "preview": "Preview",
  "add_customer_user": "Add customer user",
  "view_customer_users": "View customer users",
  "add_or_edit_machine_limits": "Add or edit machine limits",
  "add_or_edit_machine_settings": "Add or edit machine startup and cycle times",
  "download_nesting_file": `Download nesting {ext} file`,
  "download_nesting_zipped": `Download nesting {ext} files (zipped)`,
  "drop_files_to_upload": "Drop file(s) to upload",
  "preview_text": `Preview {text}`,
  "fit_to_width": "Fit to width",
  "fit_to_height": "Fit to height",
  "session_expired_error": "Session expired, please log in again",
  "scope": "Scope",
  "client_name": "Client name",
  "client_uri": "Client URI",
  "login_as_session_expired_error": "Login As session expired",
  "auth_method": "Auth Method",
  "http_basic": "HTTP Basic",
  "http_post": "HTTP POST",
  "response_types": "Response types",
  "list_view": "List view",
  "tree_view": "Tree view",
  "preparing_files_totals": "Preparing files <strong><i>({completed}/{total})</i></strong>",
  "failed_to_generate_zip": "Failed to generate ZIP file: {msg}",
  "failed_to_process_filename": "Failed to process <b>{filename}</b>, error: <b>{error}</b>",
  "manufacturer_pdf": "Manufacturer PDF",
  "order_summary": "Order Summary",
  "overall_price": "Overall Price",
  "startup_amount": "Startup Amount",
  "discount_amount": "Discount Amount",
  "shipping_amount": "Shipping Amount",
  "tax_rate": "Tax Rate",
  "created_by": "Created by",
  "set_pricing": "Set Pricing",
  "apply_price_to_customers": "Apply Price to Customers",
  "selected_customers": "Selected Customer(s)",
  "pick_pricing": "Pick Pricing",
  "add_new_pricing": "Add new pricing",
  "add_new_customer": "Add new customer",
  "pick_new_shipping_and_billing": "Pick new shipping and billing address",
  "pick_new_shipping": "Pick new shipping address",
  "pick_new_billing": "Pick new billing address",
  "make_default_billing": "Make default billing",
  "this_is_billing_address": "This is my billing address",
  "this_is_default_shipping_address": "This is my default shipping address",
  "set_as_shipping": "Set as shipping address",
  "set_as_billing": "Set as billing address",
  "default_shipping_address": "Default Shipping Address",
  "failed_to_upload": `Failed to upload file, error: "{message}"`,
  "file_duplicated": `File <strong>"{filename}"</strong> already exists, select an action to continue`,
  "proceed": "Proceed",
  "pick_new_shipping_and_billing_to_remove_address":
    "Before the address can be removed, pick a new default shipping and billing address",
  "pick_new_shipping_to_remove_address": "Before the address can be removed, pick a new default shipping address",
  "pick_new_billing_to_remove_address": "Before the address can be removed, pick a new default billing address",
  "is_moderator": "Moderator",
  "is_admin": "Admin",
  "only_show_changed_data": "Only show changed data",
}
